<template>
  <div class="container">
    <div class="delivery-help-container">
      <div class="row">
        <div class="col-12 col-md-7 delivery-help-border">
          <div class="delivery-before-pay">
            Перед оплатой Вы имеете право вскрыть посылку и проверить товар.
            После получения можно вернуть товар в течение 14 дней, даже при
            отсутствии нареканий.
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="delivery-after-pay">
            Вы 100% получите то, что видите на сайте
          </div>
        </div>
      </div>
      <div class="delivery-info">
        <span
          >При заказе {{ dateNow }} доставка в города России – бесплатно!</span
        >
      </div>
    </div>

    <!--        <page-block
            title="Доставка"
            :subtitle="
                `Акция ` +
                dateNow +
                `! Доставка до пункта выдачи в Вашем городе <span style='color: #0086fc;'>бесплатная</span>!`
            "
            align="center"
            :page-body="true"
        >-->
    <page-block
      title="Доставка"
      subtitle="Доставка до квартиры или пункта выдачи заказа"
      align="center"
      :page-body="true"
      :small-top="true"
    >
      <div class="row justify-content-center">
        <div
          class="col-12 col-md-6 col-lg-4 mb-3"
          v-for="item in list"
          :key="item.title"
        >
          <div class="delivery-card">
            <div class="delivery-card-body">
              <!--                            <div class="delivery-dates">
                                            <span v-for="head in item.header" :key="head">{{
                                                head
                                            }}</span>
                                        </div>-->
              <div class="delivery-image">
                <img :src="item.image" alt="" />
              </div>
              <div class="delivery-title">{{ item.title }}</div>
              <div class="delivery-subtitle" v-html="item.subtitle"></div>
              <!--                            <div class="delivery-list">
                                            <div
                                                class="row mx-0"
                                                v-for="el in item.elements"
                                                :key="el.name"
                                            >
                                                <div class="col-auto px-0">
                                                    <img
                                                        v-lazy="{
                                                            src: require('../assets/pin.svg'),
                                                        }"
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="col px-0">{{ el.name }}</div>
                                            </div>
                                        </div>-->
            </div>
            <div class="delivery-card-footer">
              <span class="delivery-free">{{ item.deliveryInfo }}</span>
            </div>
          </div>
        </div>
      </div>
    </page-block>
  </div>
</template>

<script>
import PageBlock from "@/components/PageBlock";
import { webp } from "@/plugins/webp";

export default {
  name: "DeliveryRussia",
  components: {
    PageBlock,
  },
  computed: {
    dateNow() {
      return this.$store.getters.date;
    },
  },
  data() {
    return {
      list: [
        {
          // header: ["3-7", "дней"],
          // elements: [
          //     {
          //         name: "Центры регионов",
          //     },
          //     {
          //         name: "Пригороды центров",
          //     },
          //     {
          //         name: "Крупные города",
          //     },
          //     {
          //         name: "Города федерал. значения",
          //     },
          // ],
          image: webp(require("../assets/rf.png")),
          // delivery_info:
          //     "Оплата при получении (можно картой) после проверки - 100% гарантия, что товар как на сайте.",
          title: "В любой город России",
          subtitle:
            "Доставка до ближайшего пункта выдачи в Вашем городе 3 -6 дней.",
          deliveryInfo: "Без предоплаты",
        },
        {
          // header: ["1", "день"],
          // elements: [
          //     {
          //         name: "Москва",
          //         url_name: "Адрес склада",
          //         target: "moscow",
          //     },
          //     {
          //         name: "Н. Новгород",
          //         url_name: "Пункт выдачи",
          //         target: "novgorod",
          //     },
          //     {
          //         name: "Киров",
          //         url_name: "Пункт выдачи",
          //         target: "kirov",
          //     },
          //     {
          //         name: "Сыктывкар",
          //         url_name: "Пункт выдачи",
          //         target: "syktyvkar",
          //     },
          // ],
          image: webp(require("../assets/msc.png")),
          // delivery_info:
          //     "Оплата при получении (можно картой) после проверки - 100% гарантия, что товар как на сайте.",
          title: "Москва и Санкт-Петербург",
          subtitle: "Доставка до ближайшего пункта выдачи 1-3 дня.",
          deliveryInfo: "Без предоплаты",
        },
        {
          // header: ["7-14", "дней"],
          // elements: [
          //     {
          //         name: "Удалённые города",
          //     },
          //     {
          //         name: "Калининград и обл.",
          //     },
          //     {
          //         name: "Поселки, деревни",
          //     },
          //     {
          //         name: "Города с авиасообщением",
          //     },
          // ],
          image: webp(require("../assets/sel.png")),
          // delivery_info:
          //     'Оплата на Почте, возможна оплата доставки. При оплате на сайте скидка на доставку. <a href="https://pay.luxstahl.ru/">Оплатить на сайте</a>.',
          title: "Удалённые города, ПГТ, сёла",
          subtitle:
            'Доставка Почтой РФ 3-9 дней до ближайшей Почты. Оплата на Почте. Возможна доп. комиссия. При <a href="https://pay.domspirt.ru/" class="text-primary text-decoration-underline">оплате на сайте</a>, комиссии нет.',
          deliveryInfo: "Возможна оплата почт. сборов",
        },
      ],
    };
  },
  mounted() {
    // let src = webp(require("../assets/rf.png"));
    // src = webp(require("../assets/msc.png"));
    // src = webp(require("../assets/sel.png"));
    // console.log(src);
  },
};
</script>

<style lang="scss" scoped>
.container {
  //margin-top: 50px;
  //margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}
.delivery-card {
  //padding: 20px 20px;
  //border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  //border: 1px solid rgb(255 255 255 / 8%);
  //border-radius: 10px;
  //overflow: hidden;
  //background: rgba(255, 255, 255, 0.0509803922);
  //backdrop-filter: blur(5px);
  background: linear-gradient(178.32deg, #424242 1.24%, #1b1b1b 110.84%);
  border-radius: 12px;
  min-height: 558px;

  &:hover {
    background: linear-gradient(
      129.22deg,
      rgba(21, 21, 22, 0.9) 0%,
      rgba(50, 50, 51, 0.9) 97.03%
    );
  }

  @media (max-width: 768px) {
    min-height: 462px;
  }

  .delivery-card-body {
    padding: 40px 30px;
    //border-top-left-radius: 10px;
    //border-top-right-radius: 10px;
    .delivery-dates {
      margin-bottom: 15px;
      border-radius: 6px;
      overflow: hidden;
      display: inline-block;
      span {
        font-weight: 500;
        font-size: 22px;

        padding: 5px 15px;
        display: inline-block;
        &:nth-child(1) {
          background: #0086fc;
        }
        &:nth-child(2) {
          background: #5c5c5c66;
        }
      }
    }
    .delivery-list {
      font-size: 17px;

      .row {
        margin-bottom: 15px;
      }

      img {
        margin-right: 15px;
      }
    }
    .delivery-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;
      margin-bottom: 20px;

      img {
        width: 100%;
        max-width: 260px;
      }
    }
    .delivery-title {
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 20px;
    }
    .delivery-subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #d6d6d6;
    }
  }
  .delivery-card-footer {
    //text-align: center;
    padding: 0 30px 40px 30px;

    //background: rgba(255, 255, 255, 0.05);
    //border-bottom-left-radius: 10px;
    //border-bottom-right-radius: 10px;
    .delivery-free {
      font-weight: 500;
      font-size: 16px;
      color: #adadad;
      padding: 9px 17px;
      background: #18181a;
      border-radius: 7px;
    }
  }
}

.delivery-help-container {
  border: 2px solid #2a80f4;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: 90px;
  @media (max-width: 767px) {
    margin-bottom: 100px;
  }

  &:before {
    left: -2px;
    border-bottom-left-radius: 15px;
    border-left: 2px solid #2a80f4;
  }
  &:after {
    right: -2px;
    border-bottom-right-radius: 15px;
    border-right: 2px solid #2a80f4;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 24%;
    height: 50%;
    border-bottom: 2px solid #2a80f4;
    bottom: 0;
    @media (max-width: 768px) {
      width: 10%;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 10%;
    }
  }

  .row {
    padding: 26px 0px 40px 26px;
    @media (max-width: 768px) {
      padding: 26px 0 50px;
    }
  }
  .delivery-help-border {
    @media (min-width: 768px) {
      border-right: 1px solid rgba(221, 222, 224, 0.25);
    }
  }
  .delivery-before-pay {
    font-size: 17px;
    line-height: 22px;
    //text-align: right;
    color: #d6d6d6;
    padding: 0 15px;

    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 5px;
    }
  }
  .delivery-after-pay {
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #ffffff;
    padding: 0 15px;

    @media (max-width: 768px) {
      text-align: center;
      padding: 5px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }

    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
  .delivery-info {
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -12px;
    display: flex;
    justify-content: center;
    z-index: 1;
    //background: #0d0d0e;
    width: fit-content;
    margin: 0 auto;
    padding: 0 50px;

    @media (max-width: 768px) {
      text-align: center;
      bottom: -18px;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }

    @media (max-width: 364px) {
      bottom: -35px;
    }

    span {
      background-image: linear-gradient(to left, #ffa353, #d95e00);
      color: transparent;

      /* purgecss start ignore */
      background-clip: text;
      -webkit-background-clip: text;
      text-shadow: 0 0 transparent;
      /* purgecss end ignore */
      z-index: 2;

      @media (max-width: 768px) {
        max-width: 100vw;
      }
    }
  }
}
</style>
